.home,
.epk {
  text-align: center;
}

/* h1 {
  font-family: 'Assistant', sans-serif;
  font-size: 98px;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 4.9px;
  line-height: 107.8px;
  margin-bottom: 0;
  margin-top: 10vh;
  padding: 10px 0;
} */


h1.page-title {
  font-size: 60px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  mix-blend-mode: difference;
  color: white;
  z-index: 1;
  /* position: relative; */
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
}

#bellbird-title {
  font-size: 60px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  mix-blend-mode: difference;
  color: white;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#bellbird-title h1 {
  font-family: 'Assistant', sans-serif;
  font-size: 98px;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 4.9px;
  line-height: 107.8px;
  -webkit-font-smoothing: antialiased;
}

#bellbird-title * {
  font-size: 30px;
}


.semi-transparent-background {
  background: rgba(255, 255, 255, 0.4);

}

.semi-transparent-background-light {
  background: rgba(255, 255, 255, 0.7);
}

.semi-transparent-background-dark {
  background: rgba(0, 15, 43, 0.67);

}

.bio {
  color: white;
  position: absolute;
  height: auto;
  font-size: 25px;
  padding: 20px;
  font-weight: bold;
  z-index: 1;
}

@media screen and (max-width: 600px) {
  .bio {
    font-size: 20px;
  }
}



.section1 {
  position: absolute;
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%, 0%);
  /* margin-top: 100px; */
  width: 70%;
  max-width: 1100px;
  height: auto;
}

.name-link {
  text-decoration: none;
  color: #aec8ff;
}

/* .bio-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
} */


.epk-section {
  margin-bottom: 30px;
  max-width: 800px;
}

.epk-section p {
  text-align: left;
}

.epk-bios {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px 30px;
  text-align: left;
}

/* paragraphs that are children of .epk-bios */
.epk-bios p {
  margin-bottom: 30px;
}


.navbar-claire {
  display: flex;
  justify-content: center;
  align-items: center;
}

#navbar-collapse-id {
  justify-content: center
}

.container-claire {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-link-claire {
  color: white;
  text-decoration: none;
  padding: 1rem 1.825rem;
  font-family: 'Assistant', sans-serif;
  font-weight: 700;
  font-size: 19px;
}

.nav-link-claire:hover {
  color: white;
  background-color: black;
}

.press-quotes {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px 30px;
  /* text-align: left; */
  font-size: 1.1em;
  width: 100% !important;
  /* TODO: width 100% is not working */

}

.press-quote-individual {
  /* margin-bottom: 30px; */
  /* display: block;
  border-bottom: 1px solid black;
  width: 100%; */
}

.quote-separator {
  /* margin: 20px 0; */
  /* border-bottom: 1px solid black; */
  width: 60%;
  margin-bottom: 2em;
}


@media (max-width: 991px) {
  .nav-link-claire {
    color: white;
    background-color: black;
  }
}

@media (max-width: 600px) {

  #bellbird-title h1 {
    font-size: 1em;
  }

  .bio {
    font-size: 1em;
  }

  .section1 {
    margin-top: 10px;
    width: 90%;
  }

  .epk-section {
    margin-bottom: 10px;
  }

  .epk-bios {
    padding: 10px 10px;
  }

  .press-quotes {
    padding: 10px 0px;
    font-size: 1em;
  }
}